.page
  *
    font-size: 1em !important
  table
    margin-bottom: 40px
    td,th
      padding: .5em 1em !important
      border: 1px solid #eee
  p
    margin-bottom: 16px
  h1,h2,h3,h4,h5,h6, strong
    font-weight: 600 !important
