// Colors
.c
  &-white
    color: $white !important
  &-dark
    color: $dark !important
  &-blue
    color: $blue !important

.b-none
  border: none !important

// Background Colors
.bg
  &-body
    background-color: $body !important
  &-white
    background-color: $white !important
  &-dark
    background-color: $dark !important
  &-light
    background-color: $light !important
  &-blue
    background-color: $blue !important
