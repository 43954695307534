
$body: #fff
$dark: #282828
$white: #fff
$light: ligten($dark,75%)

$blue: #2E85EC

$basic: 'Inter', sans-serif

=placeholder
  &::-webkit-input-placeholder
    @content
  &:-moz-placeholder
    @content
  &::-moz-placeholder
    @content
  &:-ms-input-placeholder
    @content
