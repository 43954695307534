.title
  &-logo
    color: $blue
    font-size: 1.4em
    font-weight: 600
  &-intro
    color: $dark
    font-size: 3em
    font-weight: bolder
    &.big
      font-size: 4em
      font-weight: 800
    &.desc
      color: $dark
      font-size: 1.1em
      font-weight: 500
      line-height: 1.5
  &-menu-link
    font-size: 1.05em !important
    font-weight: 500
  &-desc
    color: $dark
    font-size: 1.1em
    font-weight: normal
    line-height: 1.5
  &-h3
    color: $dark
    font-size: 1.2em
    font-weight: 700
    line-height: 1.5
  &-part
    padding-left: 56px
    padding-top: 20px
    padding-bottom: 20px
    color: $dark
    font-size: 1.75em
    font-weight: 600
    background: #ffffff00 url('../../assets/svg/home/row.svg') left center no-repeat
    background-size: 48px
    &.mini
      padding-left: 0
      padding-top: 0
      padding-bottom: 0
      font-size: 1.2em
      line-height: 1.75
      background: none
      &.feat
        display: inline-block
        padding: 8px 24px
        font-size: 0.42em
        font-weight: 500
        border-radius: 8px
        border: 2px solid $blue
      em
        display: inline-flex
        margin-left: 10px
        height: 24px
        justify-content: center
        align-items: center
        padding: 0 8px !important
        font-size: .85em
        font-style: normal
        font-weight: 400
        border-radius: 12px
        transform: translateY(-1px)

footer
  .row
    opacity: .7
  a
    &:hover
      color: $dark !important
  .title-logo
    color: lighten($dark, 15%)
  .title-parts
    &.mini
      font-size: 0.98em
      font-weight: 500 !important

.feat-circle
  display: inline-flex
  justify-content: center
  align-items: center
  margin-right: 10px
  min-width: 40px
  min-height: 40px
  color: $white
  font-size: .55em !important
  border-radius: 20px
  background-color: $blue

.features
  background: #ffffff00 url('../../assets/svg/home/rocket.svg') 2vw 15vh no-repeat
  background-size: 30%
  padding-bottom: 5vh
  +v-md
    background: none

.steps
  background: #ffffff00 url('../../assets/svg/steps/left.svg') 30px no-repeat
  +v-md
    background: none

img
  +v-md
    max-width: 96% !important

.m-hide
  +v-md
    display: none !important

.m-tc
  +v-md
    text-align: center !important

.title-part.w-60.ma
  +v-md
    min-width: 80% !important
    max-width: 80% !important
    width: 80% !important
    margin-top: 50px
  +v-sm
    min-width: 80% !important
    max-width: 80% !important
    width: 80% !important
    margin-top: 50px
  +v-xs
    min-width: 80% !important
    max-width: 80% !important
    width: 80% !important
    margin-top: 50px

footer
  .container
    +v-sm
      width: 80% !important

.w-70
  +v-md
    min-width: 100% !important
    max-width: 100% !important
    width: 100% !important
  +v-sm
    min-width: 100% !important
    max-width: 100% !important
    width: 100% !important
  +v-xs
    min-width: 100% !important
    max-width: 100% !important
    width: 100% !important
.w-50
  +v-md
    min-width: 100% !important
    max-width: 100% !important
    width: 100% !important
  +v-sm
    min-width: 100% !important
    max-width: 100% !important
    width: 100% !important
  +v-xs
    min-width: 100% !important
    max-width: 100% !important
    width: 100% !important
.w-80
.right-steps.w-50
  +v-md
    min-width: 100% !important
    max-width: 100% !important
    width: 100% !important
  +v-sm
    min-width: 100% !important
    max-width: 100% !important
    width: 100% !important
  +v-xs
    min-width: 100% !important
    max-width: 100% !important
    width: 100% !important

.right-steps,
.left-steps
  +v-md
    text-align: center !important

p.c-dark.my-4.h-10
  +v-sm
    width: 100%
    margin-left: -12%

.r-16.h-70.flex.jcc.aic.bg-light
  +v-md
    height: 100%
  +v-sm
    padding-top: 40px
    padding-bottom: 40px

span.feat-circle
  +v-sm
    margin-left: 10%
  +v-xs
    margin-left: 5%

.feat
  +v-sm
    margin-right: 10%
  +v-xs
    margin-right: 5%

.grid-content
  columns: 2 auto
  p
    padding: 0 10px !important
  +v-xs
    columns: 1 auto
