
.card{
    padding: 2em;
    margin-bottom: 1em;
    background-color: #E9E9E9;
    border-radius: 30px;
    height: 100%;
    min-width: 30%;
}

