.pagination-wrapper {
  display: flex;
  gap: 5px;
  width: fit-content;
}

.pagination {
  display: flex;
  gap: 5px;
}

@media screen and (max-width: 500px) {
  .pagination-wrapper {
    flex-direction: column;
  }
}

.pagination-arrows__wrapper {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 10px;
}

.pagination-elipsis {
  display: flex;
  padding: 0 10px;
  align-items: end;
}

.pagination-btn {
  padding: 5px 10px;
  border-radius: 6px;
  border: 2px solid #0d6efd;
  color: #0d6efd;
  background-color: transparent;
  font-weight: 500;
}
.pagination-btn:hover {
  color: #fff;
  background-color: #0d6efd;
}

.pagination-btn--active {
  color: #fff;
  background-color: #0d6efd;
  pointer-events: none;
}

.pagination-arrow {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px 10px;
  border: 2px solid #0d6efd;
  border-radius: 6px;
  background: transparent;

  transition: all 0.3s ease-in-out;
}

.pagination-arrow:hover {
  background-color: #0d6efd;
}

.pagination-arrow svg {
  fill: #0d6efd;
  transition: all 0.3s ease-in-out;
}
.pagination-arrow:hover svg {
  fill: #fff;
}
