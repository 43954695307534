.button
  display: inline-flex
  justify-content: center
  align-items: center
  height: 40px
  min-width: 160px
  padding: 0 24px
  color: $white !important
  font-size: 1em
  font-weight: normal
  border-radius: 20px
  transition: all ease-out .5s
  background-color: $blue
  border: none !important
  outline: none !important
  &:hover
    background-color: $dark
  &.cat
    width: 23%
    display: inline-flex
    color: $blue !important
    font-size: 1.1em
    border: 2px solid $light !important
    background-color: #f4f4f4
    border-radius: 8px
    &:hover
      color: $white !important
      background-color: $blue
  &.nav-button
    margin-left: 1rem
    font-size: 1.1em !important
    color: $blue !important
    border: 2px solid $blue !important
    background-color: #ffffff00
    &:hover
      color: $white !important
      background-color: $dark
      border: 2px solid $dark !important
