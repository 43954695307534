/* Base */

html
  font-family: $basic
  font-weight: normal
  font-size: 1rem
  font-display: swap
body
  width: 100%
  max-width: 100% !important
  color: $dark
  font-size: 80% !important
  font-family: $basic
  font-weight: normal
  font-smooth: always
  letter-spacing: .0025em
  -webkit-font-smoothing: antialised
  -moz-osx-font-smoothing: grayscale
  overflow-x: hidden
  background-color: $white

a, * a
  color: inherit !important
  text-decoration: none !important

.support
  width: 100%

.dropdown-menu[data-bs-popper]
  left: auto
  right: 0
  margin-top: 8px
  border: 1px solid #eee

.dropdown-item
  &.active
    display: none

.dropdown
  .btn
    &.btn-light
      background-color: $white
      &:hover
        background-color: $light
        border: 1px solid #eee

.dropdown-scroll
  overflow-y: auto
  max-height: 80vh
  &::-webkit-scrollbar
    width: 6px
  &::-webkit-scrollbar-track
    background-color: $white
    box-shadow: none
    border-radius: 3px
  &::-webkit-scrollbar-thumb
    background: #eee
    border-radius: 3px
  &::-webkit-scrollbar-thumb:hover
    background: #eee

.scroll-wrapper
  padding-right: 8px

.slick-dots
  bottom: -80px !important
  li
    margin: 0

.avatar
  width: 56px
  height: 56px
  margin-left: -4%
  object-fit: cover
  display: flex
  justify-content: center
  align-items: center
  border: 4px solid $white
  font-weight: 500
  &.bg-blue
    width: 48px
    height: 48px
    border: none

.logo-img
  display: inline-block
  transform: translateY(-2px)

footer
  .logo-img
    filter: grayscale(100%)

// hide 3 languages
a
  &[data-key="27"],
  &[data-key="30"],
  &[data-key="35"]
    display: none

.hot
  &-title
    font-size: 1.3em !important
  &-text
    height: 120px

.nav-top
  position: relative
  .nav-lang
    position: absolute
    right: 0
  .navbar-collapse
    margin-right: 80px


.loader-wrapper
  min-height: 75vh
  display: flex
  justify-content: center
  align-items: center
